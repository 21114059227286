/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const {wrapRootElement} = require("./wrapRootElement")

exports.onInitialClientRender = () => {
  setTimeout(function() {
    document.body.style.overflowY = "initial"
    document.getElementById("___loader").style.display = "none"
  }, 250)
}

exports.wrapRootElement = wrapRootElement

// You can delete this file if you're not using it
// eslint-disable-next-line react/prop-types
// export {wrapRootElement} from './wrapRootElement';


import { createStore as reduxCreateStore, applyMiddleware } from "redux"
import { composeWithDevTools } from 'redux-devtools-extension';

import reducer from "./reducer"
import initialState from "./initialState"


const createStore = () =>
  reduxCreateStore(reducer, initialState, composeWithDevTools(
    applyMiddleware(),
    // other store enhancers if any
  ))
export default createStore

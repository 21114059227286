import * as actionType from "./actionTypes"
import initialState from "./initialState"

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_USER:
      // console.log("Set user was called", action.payload)
      return {
        ...state,
        user: action.payload
      };
    case actionType.RESET_USER:
      // console.log("Reset User is called")
      return {
        ...state,
        user: {
          ...initialState.user
        }
      };
    default:
      return state;
  }

}

export default reducer
